<template>
    <div>
        <section class="text-section">
            <div class="container">
                <h1>Правила пользования</h1>
                <p>
                    Настоящие Правила пользования (далее – Правила) определяют условия использования
                    веб-сервиса «Байторг» - сайта, расположенного и доступного в глобальной компьютерной
                    сети Интернет по адресу {{baseUrl}}, на котором Сервисом размещается информация (сведения)
                    из открытых источников о государственных и коммерческих закупках в Республике Беларусь,
                    а также мобильных приложений «Bytorg», разработанных и предназначенных для использования
                    в тех же целях, что и веб-сервис «Байторг», в совокупности представляющих собой Программный продукт.
                    Правила определяют взаимные права и обязанности, возникающие между обществом с ограниченной
                    ответственностью «Байторг Бай» – владельцем Программного продукта, с одной стороны, и любым
                    пользователем, соответствующим понятию Пользователя настоящих Правил и изъявившим желание
                    воспользоваться Программным продуктом, принявшим (акцептовавшим) настоящее публичное
                    предложение (оферту), именуемым в дальнейшем «Пользователь» с другой стороны, совместно
                    именуемыми «Стороны».
                </p>
                <h2>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В ПРАВИЛАХ.</h2>
                <p>
                    В настоящих Правилах, если из текста Правил прямо не вытекает иное, следующие слова и выражения
                    будут иметь указанные ниже значения:
                </p>
                <p>
                    1.1. Программный продукт – совокупность программных и аппаратных средств для ЭВМ, обеспечивающих
                    публикацию для всеобщего обозрения информации и данных, объединенных общим целевым назначением,
                    посредством технических средств, применяемых для связи между ЭВМ в сети Интернет, предназначенная
                    для предоставления услуг в соответствии с условиями и правилами, размещенными на веб-сервисе
                    «Байторг» - сайте, расположенном и доступным в глобальной компьютерной сети Интернет по адресу
                    {{baseUrl}}, включая как программное обеспечение, так и его дизайн (графическое оформление),
                    база данных, любой раздел (подраздел), Информацию, размещенную в Программном продукте Сервисом
                    и/или Пользователями, а так же мобильные приложения «Bytorg» – программное обеспечение,
                    предназначенное для работы на смартфонах, планшетах и других мобильных устройствах, разработанное
                    для конкретной платформы (iOS, Android, Windows Phone и т. д.), предназначенное для тех же целей.
                </p>
                <p>
                    1.2. Функционал Программного продукта – специальные возможности Программного продукта, в том числе
                    настраиваемые фильтры и другие инструменты, облегчающие поиск, систематизацию и обработку
                    информации, позволяющие Пользователю, который использует Программный продукт в Пробный период или
                    приобрел Подписку, осуществлять поиск интересующей его информации о государственных и коммерческих
                    закупках в Республике Беларусь.
                </p>
                <p>
                    1.3. Сервис – Общество с ограниченной ответственностью «Байторг Бай», в лице директора,
                    Быховец П.Е., действующего на основании Устава, а равно уполномоченные им лица по управлению
                    Программным продуктом, действующие от имени владельца Программного продукта.
                </p>
                <p>
                    1.4. Пользователь – идентифицированное или не идентифицированное дееспособное физическое лицо,
                    индивидуальный предприниматель, представитель юридического лица, использующий Программный продукт
                    и (или) зарегистрировавшийся в Программном продукте в установленном порядке путем прохождения
                    процедуры регистрации и создания Профиля.
                </p>
                <p>
                    1.5. Профиль (мой Профиль) – персональный раздел Программного продукта, к которому Пользователь
                    получает доступ после прохождения процедуры регистрации и авторизации в Программном продукте,
                    предназначенный для хранения персональных данных Пользователя, в том числе данных об организации
                    Пользователя, в случае если Пользователь является представителем юридического лица, сведений об
                    индивидуальном предпринимателе, в случае если Пользователь является индивидуальным предпринимателем,
                    просмотра и управления доступными функциональными возможностями Программного продукта, просмотра
                    истории подписок, получения уведомлений.
                </p>
                <p>
                    1.6. Пробный период – возможность Пользователя использовать функционал Программного продукта
                    бесплатно в течение определенного периода времени с момента прохождения процедуры регистрации
                    в Программном продукте. При этом срок Пробного периода определяется на усмотрение Сервиса.
                </p>
                <p>
                    1.7. Подписка – возможность Пользователя использовать функционал Программного продукта при условии
                    приобретения Подписки.
                </p>
                <p>
                    1.8. Приобретение подписки – оплата Пользователем выставленного Сервисом счета за предоставление
                    возможности использовать функционал Программного продукта.
                </p>
                <p>
                    1.9. Информация – любая информация, размещаемая (размещенная) Пользователем или Сервисом
                    в Программном продукте, в том числе: персональные данные Пользователя, ссылки на другие сайты,
                    любые текстовые сообщения, фотографии (изображения), аудио- и/или видео-произведения,
                    компьютерные программы, и др. файлы.
                </p>
                <p>
                    1.10. Контент – текстовые, аудио- и видеоматериалы, изображения, а также иные объекты
                    интеллектуальной собственности, авторских и (или) смежных прав, а равно не являющиеся таковыми,
                    информация и сообщения любого характера.
                </p>

                <h2>2. ПОРЯДОК ЗАКЛЮЧЕНИЯ И ВНЕСЕНИЯ ИЗМЕНЕНИЙ В НАСТОЯЩИЕ ПРАВИЛА. </h2>
                <p>
                    2.1. Настоящие Правила разработаны в соответствии с Гражданским кодексом Республики Беларусь
                    (далее – ГК), другими нормативными правовыми актами Республики Беларусь, определяют правила
                    использования Программного продукта и в силу ст. 398 ГК являются договором присоединения.
                </p>
                <p>
                    2.2. Заключение настоящих Правил производится путем присоединения, т.е. посредством
                    принятия (акцепта) Пользователем условий настоящих Правил в целом, без каких-либо условий, изъятий
                    и оговорок (статья 398 Гражданского кодекса Республики Беларусь).
                </p>
                <p>
                    2.3. Доведение текста настоящих Правил до Пользователя в Программном продукте является публичной
                    офертой Сервиса в соответствии с пунктом 2 статьи 407 Гражданского кодекса Республики Беларусь.
                </p>
                <p>
                    2.4. Используя Программный продукт, Пользователь соглашается с условиями настоящих Правил
                    путем присоединения.
                </p>
                <p>
                    2.5. Фактом присоединения к настоящим Правилам и их акцепта признается прохождение процедуры
                    регистрации с проставлением отметки в графе «Я согласен с Правилами пользования».
                </p>
                <p>
                    2.6. Фактическое использование Программного продукта означает безоговорочное согласие Пользователя
                    со всеми пунктами настоящих Правил и безоговорочное принятие их условий с обязательствами соблюдать
                    обязанности, возложенные на Пользователя настоящими Правилами.
                </p>
                <p>

                    2.7. Сервис вправе изменять условия настоящих Правил и всех его неотъемлемых частей с уведомлением
                    Пользователя посредством размещения в Программном продукте новой редакции настоящих Правил.
                </p>
                <p>
                    2.8. Новая редакция Правил вступает в силу с момента опубликования в Программном продукте,
                    если иной срок вступления изменений в силу не определен Сервисом при их опубликовании.
                </p>
                <p>
                    2.9. Действующая редакция Правил и всех приложений к ним всегда находится в публичном доступе
                    по адресу: {{baseUrl}}/{{termsOfUsePath}}. Предыдущие редакции Правил могут предоставляться
                    по запросу Пользователей.
                </p>
                <p>
                    2.10. Использование Программного продукта, включая посещение/открытие страниц Программного продукта
                    независимо от прохождения процедуры регистрации на нем, означает согласие Пользователя быть
                    связанным самой актуальной редакцией настоящих Правил.
                </p>
                <p>
                    2.11. В случае несогласия с любым положением настоящих Правил, Пользователь должен незамедлительно
                    прекратить использование Программного продукта на своем устройстве.
                </p>
                <p>
                    2.12. Предоставление отдельных услуг может регулироваться специальными правилами и/или соглашениями,
                    являющимися неотъемлемой частью настоящих Правил. В случае противоречия или несоответствия между
                    текстом настоящих Правил и специальными правилами и/или соглашениями применению подлежат последние.
                </p>
                <p>
                    2.13. Программный продукт и Сервис не является средством массовой информации в соответствии с
                    законодательством Республики Беларусь.
                </p>

                <h2>3. ДОСТУП К ПРОГРАММНОМУ ПРОДУКТУ И ЕГО ИСПОЛЬЗОВАНИЕ</h2>
                <p>
                    3.1. Пользователь обязуется использовать Программный продукт только в законных целях.
                </p>
                <p>
                    3.2. Использование Программного продукта возможно как с прохождением, так и без прохождения
                    процедуры регистрации.
                </p>
                <p>
                    3.3. Использование Программного продукта без прохождения регистрации позволяет:
                </p>
                <p>
                    3.3.1. Просматривать Контент.
                </p>
                <p>
                    3.3.2. Использовать средства поиска и навигации Программного продукта.
                </p>
                <p>
                    3.3.3. Переходить на внешние сайты, источники информации, по ссылкам, указанным в Программном
                    продукте (при их наличии).
                </p>
                <p>
                    3.4. Прохождение процедуры регистрации дополнительно позволяет Пользователю:
                </p>
                <p>
                    3.4.1. Размещать/изменять информацию о Пользователе в Профиле.
                </p>
                <p>
                    3.4.2. Получить доступ к Функционалу Программного продукта в течение Пробного периода.
                </p>
                <p>
                    3.4.3. Получить доступ к Функционалу Программного продукта путем приобретения Подписки.
                </p>

                <h2>4. ПОРЯДОК РЕГИСТРАЦИИ И ПОСЛЕДУЮЩИЕ ДЕЙСТВИЯ</h2>
                <p>
                    4.1. Регистрация в Программном продукте происходит с использованием номера мобильного телефона и
                    адреса электронной почты в установленной регистрационной форме.
                </p>
                <p>
                    4.2. Активация Профиля Пользователя производится путем отправки сообщения, содержащего ссылку для
                    активации Профиля Пользователя, на адрес электронной почты, указанный Пользователем при прохождении
                    процедуры регистрации. После прохождения Пользователем по ссылке активации из полученного от Сервиса
                    сообщения по электронной почте создается Профиль.
                </p>
                <p>
                    4.3. Все действия, которые совершены с использованием Профиля, считаются совершенными лично
                    Пользователем. Вся информация, полученная с использованием Профиля, считается информацией,
                    использованной лично Пользователем или с согласия Пользователя.
                </p>
                <p>
                    4.4. При регистрации Профиля Пользователь указывает следующие данные:
                </p>
                <ul>
                    <li>- адрес электронной почты (e-mail);</li>
                    <li>- номер мобильного телефона;</li>
                    <li>- пароль (пароль может содержать цифры, буквы и прочие символы);</li>
                    <li>- подтверждение пароля.</li>
                </ul>
                <p>
                    4.5. В случае заинтересованности в использовании Функционала Программного продукта Пользователь
                    имеет право как непосредственно после прохождения процедуры регистрации, так и по истечению срока
                    Пробного периода приобрети Подписку.
                </p>
                <p>
                    4.6. Приобретение Подписки доступно только Пользователям, которые являются юридическими лицами
                    или индивидуальными предпринимателями.
                </p>
                <p>
                    4.7. Приобретение Подписки осуществляется в разделе «Подписка» Профиля Пользователя посредством
                    нажатия кнопки «Продлить подписку».
                </p>
                <p>
                    4.8. После нажатия кнопки «Продлить подписку» Пользователь обязуется выбрать дату начала Подписки.
                    При этом дата начала подписки не может быть раньше даты окончания имеющейся подписки
                    или Пробного периода.
                </p>
                <p>
                    4.9. После выбора даты начала Подписки Пользователь обязуется заполнить предложенную форму,
                    указав в ней все необходимые сведения, в том числе:
                </p>
                <ul>
                    <li>- полное наименование организации в соответствии со свидетельством о
                        регистрации (Ф.И.О. индивидуального предпринимателя);
                    </li>
                    <li>- краткое наименование организации (фамилию и инициалы индивидуального предпринимателя);</li>
                    <li>- страну и регион, в которой находится юридическое лицо (индивидуальный предприниматель);</li>
                    <li>- УНП или ИНН (номер налогоплательщика);</li>
                    <li>- адрес местонахождения (юридический адрес) юридического лица
                        (индивидуального предпринимателя);
                    </li>
                    <li>- информацию о руководителе юридического лица (Ф.И.О.);</li>
                    <li>- банковские реквизиты юридического лица (индивидуального предпринимателя);</li>
                    <li>- сведения о контактном лице юридического лица (индивидуального предпринимателя), в том
                        числе Ф.И.О., номер телефона, адрес электронной почты.
                    </li>
                </ul>
                <p>
                    4.10. Для целей проверки заявленных сведений Сервис вправе запросить подтверждающие
                    документы (в частности, для юридических лиц: устав, свидетельство о регистрации юридического лица,
                    доверенность представителя и пр.; для индивидуальных предпринимателей – паспорт, свидетельство о
                    государственной регистрации), непредоставление которых, может быть приравнено к предоставлению
                    недостоверной информации. В случае если данные, указанные в предоставленных документах,
                    не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные
                    при регистрации, не позволяют идентифицировать пользователя, Сервис вправе отказать Пользователю
                    в Приобретении подписки.
                </p>
                <p>
                    4.11. Завершение Приобретения Подписки возможно только при условии указания Пользователем всех
                    необходимых сведений в предложенной форме, согласия Пользователя с условиями Договора оказания
                    услуг, а также оплаты Пользователем соответствующего счета, выставленного Сервисом.
                </p>
                <p>
                    4.12. Согласием Пользователя с условиями Договора оказания услуг признается проставление
                    Пользователем отметки в графе «Согласен с условиями договора оказания услуг».
                </p>
                <p>
                    4.13. Для Приобретения Подписки Пользователь обязуется предоставить достоверную и полную информацию
                    о себе по вопросам в предложенной форме. В дальнейшем Пользователь обязуется поддерживать данную
                    информацию в актуальном состоянии в Профиле.
                </p>
                <p>
                    4.14. Если Пользователь предоставляет неверную и/или недостоверную и/или неактуальную информацию
                    или у Сервиса есть основания полагать, что предоставленная информация неполная и/или недостоверна
                    и/или неактуальная, Сервис имеет право по своему усмотрению заблокировать либо удалить Профиль
                    и отказать Пользователю в использовании Функционала Программного продукта или Программного
                    продукта в целом.
                </p>
                <p>
                    4.15. Вход Пользователя в Профиль осуществляется посредством процедуры
                    авторизации – введения логина и пароля.
                </p>
                <p>
                    4.16. При невозможности входа в Профиль в связи с утерей пароля, блокировкой Профиля и по иным
                    причинам, Пользователь может обратиться в службу поддержки Сервиса, Способы восстановления
                    авторизации Пользователя могут быть изменены Сервисом в одностороннем порядке.
                </p>
                <p>
                    4.17. Пользователь вправе хранить логин и пароль любыми удобными для него способами, которые
                    обеспечат защиту данной информации. Пользователь вправе использовать любые технические способы
                    хранения логина и пароля, в том числе файлы cookies, позволяющие последующую автоматическую
                    авторизацию Пользователя.
                </p>
                <p>
                    4.18. Пользователь самостоятельно несет ответственность за сохранность своего логина и пароля.
                </p>
                <p>
                    4.20. Срок размещения Профиля не ограничен и определяется самим Пользователем, за исключением
                    случаев нарушения им условий настоящих Правил, в результате которых Сервис вправе заблокировать
                    или удалить Профиль.
                </p>

                <h2>5. ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ</h2>
                <p>
                    5.1. Пользователь вправе:
                </p>
                <p>
                    5.1.1. Использовать Программный продукт на условиях настоящих Правил, в том числе:
                </p>
                <p>
                    5.1.1.1. Зарегистрироваться в Программном продукте и разместить Контент о себе.
                </p>
                <p>
                    5.1.1.2. Сообщать Сервису о наличии Контента, нарушающего его права или несоответствующих
                    условиям настоящих Правил или законодательству Республики Беларусь.
                </p>
                <p>
                    5.1.1.3. Заблокировать Профиль, обратившись к Сервису.
                </p>
                <p>
                    5.2. Пользователь обязан:
                </p>
                <p>
                    5.2.1. Соблюдать нормы законодательства Республики Беларусь и положения настоящих Правил.
                </p>
                <p>
                    5.2.2. При использовании Программного продукта, предоставлять только достоверные сведения,
                    поддерживать их актуальность и нести за них ответственность.
                </p>
                <p>
                    5.2.3. Не предоставлять доступ третьим лицам к собственным Профилю и/или логину и паролю
                    для любых целей.
                </p>
                <p>
                    5.2.4. Предпринимать все действия по сохранности пароля.
                </p>
                <p>
                    5.2.5. Перед размещением Контента предварительно оценивать законность размещения.
                </p>
                <p>
                    5.2.6. В случае наличия сомнений в отношении законности осуществления тех или иных действий, в том
                    числе, по размещению информации воздержаться от осуществления последних.
                </p>
                <p>
                    5.2.7. Предоставлять запрашиваемые Сервисом документы и информацию, в течение 2 (двух) рабочих дней
                    с момента поступления соответствующего запроса.
                </p>
                <p>
                    5.2.8. Самостоятельно оценивать все риски, связанные с использованием Функционала Программного
                    продукта.
                </p>
                <p>
                    5.2.9. Нести самостоятельную ответственность по обязательствам, вытекающим из любых сделок и (или)
                    соглашений, решение о заключении которых было принято на основании информации и (или) сведений,
                    полученных в Программном продукте.
                </p>
                <p>
                    5.2.10. Пользователь обязан немедленно уведомить Сервис о любом случае несанкционированного
                    (не разрешенного Пользователем) доступа к Программному продукту с использованием Профиля
                    Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своих средств
                    доступа к Профилю.
                </p>
                <p>
                    5.3. Незамедлительно сообщить Сервису о:
                </p>
                <p>
                    5.3.1. Несанкционированном доступе третьих лиц к Профилю.
                </p>
                <p>
                    5.3.2. Распространении третьим лицам логина и пароля.
                </p>
                <p>
                    5.4. Пользователю запрещается:
                </p>
                <p>
                    5.4.1. Распространять информацию от имени организаций, не прошедших в установленном порядке
                    государственную регистрацию (перерегистрацию) в случаях, когда такая регистрация (перерегистрация)
                    является обязательной в соответствии с законодательными актами Республики Беларусь, а также
                    организаций, в отношении которых имеется вступившее в законную силу решение уполномоченного
                    государственного органа об их ликвидации; сведения, пропагандирующие потребление наркотических
                    средств, психотропных веществ, их аналогов, токсических и других одурманивающих веществ,
                    а также сведения о способах и методах разработки, изготовления, использования и местах приобретения
                    наркотических средств, психотропных веществ, их прекурсоров и аналогов; информацию
                    о несовершеннолетнем, пострадавшем в результате противоправных действий (бездействия), включая
                    фамилию, собственное имя, отчество (если имеется), фото- и видеоизображения этого
                    несовершеннолетнего, его родителей или иных законных представителей, дату рождения
                    несовершеннолетнего, аудиозапись его голоса, место его жительства, учебы или работы, иную
                    информацию, позволяющую прямо или косвенно установить личность этого несовершеннолетнего,
                    без согласия его законного представителя; информацию о способах изготовления взрывных устройств и
                    взрывчатых веществ, а также предметов, поражающее действие которых основано на использовании горючих
                    веществ; ненадлежащую рекламу; информацию, направленную на пропаганду войны, экстремистской
                    деятельности или содержащей призывы к такой деятельности, порнографии, насилия и жестокости, в том
                    числе пропагандирующей или побуждающей к самоубийству, другую информацию, распространение которой
                    способно нанести вред национальным интересам Республики Беларусь или запрещено настоящим Законом,
                    иными законодательными актами Республики Беларусь.
                </p>
                <p>
                    5.4.2. Любыми способами пытаться получить доступ к Профилю другого Пользователя.
                </p>
                <p>
                    5.4.3. Предпринимать любые действия, в том числе технического характера, направленные на нарушение
                    надлежащего функционирования Программного продукта.
                </p>
                <p>
                    5.4.4. Предпринимать действия, которые могут привести к большой нагрузке на инфраструктуру
                    Программного продукта.
                </p>
                <p>
                    5.4.5. Использовать любые запрещенные законодательством технические средства для сбора и обработки
                    информации в Программном продукте.
                </p>
                <p>
                    5.4.6. Загружать, посылать, передавать или любым другим способом размещать и/или распространять
                    какие-либо материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, которые
                    могут нарушить, уничтожить либо ограничить функциональность любого компьютерного или
                    телекоммуникационного оборудования или программ, или обеспечить несанкционированный доступ
                    к Программному продукту.
                </p>
                <p>
                    5.4.7. Размещать ссылки на сайты, содержание которых противоречит законодательству Республики
                    Беларусь, а также другим образом нарушать нормы законодательства, в том числе нормы
                    международного права.
                </p>
                <p>
                    5.4.8. Размещать в Программном продукте, направлять куда-либо посредством Программного продукта
                    любые материалы следующего характера:
                </p>
                <p>
                    5.4.8.1 Нарушающие законодательство Республики Беларусь, содержащие угрозы и оскорбления,
                    дискредитирующие других лиц, нарушающие права граждан на частную жизнь или публичный порядок,
                    носящие характер непристойности;
                </p>
                <p>
                    5.4.8.2. Нарушающие в той или иной степени честь и достоинство, права и охраняемые законом интересы
                    других лиц;
                </p>
                <p>
                    5.4.8.3. Способствующие или содержащие призывы к разжиганию религиозной, расовой или межнациональной
                    розни, содержащие попытки разжигания вражды или призывы к насилию;
                </p>
                <p>
                    5.4.8.4. Иные материалы, которые побуждают других лиц на противоправное поведение, влекущее
                    уголовную, гражданско-правовую и иную ответственность или каким-либо образом нарушающее
                    положения законодательства.
                </p>
                <p>
                    5.4.8.5. Размещать любую другую информацию, которая не соответствует целям создания Программного
                    продукта, ущемляет интересы Пользователей или по другим причинам является неприемлемой для
                    размещения в Программном продукте.
                </p>

                <h2>
                    6. ПРАВА И ОБЯЗАННОСТИ СЕРВИСА
                </h2>
                <p>
                    6.1. Сервис вправе:
                </p>
                <p>
                    6.1.1. В любое время без предварительного уведомления Пользователей вносить изменения в условия
                    настоящих Правил и других регламентирующих документов.
                </p>
                <p>
                    6.1.2. В любое время и любым способом изменять Программный продукт, в том числе изменять или
                    добавлять разделы, изменять его структуру, дизайн и Контент, а также дополнять, изменять,
                    ограничивать, расширять Программный продукт, в том числе условия доступа Пользователей
                    к программному продукту и его элементам.
                </p>
                <p>
                    6.1.3. Без предварительного уведомления Пользователя заблокировать его Профиль в случаях
                    несоблюдения Пользователем положений настоящих Правил;
                </p>
                <p>
                    6.1.4. При этом, блокирование Профиля может быть временным или постоянным, в зависимости
                    от объема и количества допущенных Пользователем нарушений. В случае устранения нарушений Сервис
                    вправе восстановить доступ к Профилю.
                </p>
                <p>
                    6.1.5. Без предварительного уведомления и согласия Пользователя удалить его Профиль без
                    возможности восстановления в случае систематического (3 и более раз) несоблюдения Пользователем
                    положений настоящих Правил.
                </p>
                <p>
                    6.1.6. Оказывать Пользователям платные услуги на условиях, предусмотренных заключенными между
                    Сервисом и Пользователем договорами.
                </p>
                <p>
                    6.1.7. Отправлять Пользователям по электронной почте сообщения, связанные с тематикой Программного
                    продукта, а также с ее использованием.
                </p>
                <p>
                    6.1.8. Запрашивать документы и информацию, необходимые для определения Пользователя как стороны
                    настоящих Правил и/или стороны, использующей платные услуги Сервиса, а также документы,
                    подтверждающие достоверность представленных сведений.
                </p>
                <p>
                    6.1.9. Без уведомления Пользователя проводить профилактические работы, влекущие приостановление
                    работы Программного продукта, на срок не более 48 (сорок восемь) часов подряд.
                </p>
                <p>
                    6.1.10. Собирать, обрабатывать и использовать информацию о местоположении Пользователя,
                    когда Пользователь пользуется функционалом Программного продукта, позволяющую определять и
                    показывать маршрут до интересующих Пользователя объектов. Информация о местоположении позволяет
                    определить местоположение Пользователя с определенной степенью точности, учитывая следующие факторы:
                </p>
                <ul>
                    <li>- данные GPS;</li>
                    <li>- IP-адрес;</li>
                    <li>- данные с датчиков на устройстве, с которого используется доступ к Веб-сервису;</li>
                    <li>- сведения об объектах вокруг Пользователя, например, точках доступа Wi-Fi, антеннах сотовой
                        связи и устройствах с включенным модулем Bluetooth.
                    </li>
                    <li></li>
                </ul>
                <p>
                    Типы информации о местоположении, которые собирает и обрабатывает Сервис, частично зависят от
                    настроек используемого Пользователем устройства и настроек Профиля Пользователя. Согласие
                    Пользователя на использование Сервисом информации о местоположении должно быть явно
                    выражено Пользователем.
                </p>
                <p>
                    6.1.11. Выполнять другие действия, направленные на улучшение качества работы Программного продукта,
                    и удобства его использования.
                </p>
                <p>
                    6.2. Сервис обязан:
                </p>
                <p>
                    6.2.1. Не передавать третьим лицам информацию Пользователя, которая не размещена в открытом доступе
                    за исключением случаев, указанных в п.6.2.2. настоящих Правил.
                </p>
                <p>
                    6.2.2. По требованию уполномоченного государственного органа в соответствии с действующим
                    законодательство передавать такому государственному органу имеющуюся информацию о Пользователе.
                </p>

                <h2>7. КОНТЕНТ</h2>
                <p>
                    7.1. Исключительные права на все размещенные в Программном продукте объекты, в том числе базы
                    данных, элементы дизайна, тексты, графические изображения, иллюстрации, фотографии, другие объекты
                    и любой Контент, принадлежат Сервису и другим правообладателям. В отношении объектов, являющихся
                    результатом интеллектуальной деятельности иных лиц, находящихся в Программном продукте,
                    Пользователям предоставляется неисключительная (простая) лицензия.
                </p>
                <p>
                    7.2. При размещении Контента Пользователь должен руководствоваться рекомендациями Программного
                    продукта по их оформлению.
                </p>
                <p>
                    7.3. Пользователь обязан размещать только Контент, который не запрещено использовать
                    законодательством Республики Беларусь.
                </p>
                <p>
                    7.4. Пользователь не вправе размещать, копировать (воспроизводить), перерабатывать, распространять,
                    опубликовывать, скачивать, передавать, продавать или иным способом использовать целиком или по
                    частям Контент без предварительного разрешения правообладателя, кроме случаев, когда правообладатель
                    явным образом выразил свое согласие на свободное использование Контента любым лицом.
                </p>
                <p>
                    7.5. Пользователь предоставляет Сервису неисключительное право использовать на безвозмездной основе]
                    размещенный в Программном продукте и принадлежащий ему на законных основаниях Контент в целях
                    обеспечения Сервисом работы Программного продукта в объеме, необходимом для его надлежащего
                    функционирования. Указанное неисключительное право предоставляется на срок размещения Контента в
                    Программном продукте, включает право переработки Контента путем размещения и для последующего показа
                    совместно с Контентом и/или с его использованием Контента рекламной информации и распространяет свое
                    действие на территории стран всего мира. Сервис вправе передавать права, указанные в настоящем
                    пункте третьим лицам.
                </p>
                <p>
                    7.6. Любое использование Программного продукта или Контента, кроме разрешенного настоящими Правилами
                    или в случае явно выраженного согласия правообладателя на такое использование, запрещено без
                    предварительного письменного разрешения правообладателя.
                </p>

                <h2>8. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p>
                    8.1. Функционал Программного продукта, в том числе любые дополнительные возможности, которые
                    допускает Функционал Программного продукта, предоставляются Пользователю на условиях «как есть» и
                    Сервис не предоставляет Пользователю каких-либо гарантий в том, что:
                </p>
                <ul>
                    <li>- объем функционала Программного продукта будет отвечать требованиям Пользователя;</li>
                    <li>- доступ к Функционалу Программного продукта не будет прерываться, и будет всегда доступен
                        в удобное для Пользователя время, будет безопасным или не будет подвержен ошибкам и сбоям;
                    </li>
                    <li>- любая информация, полученная в результате использования функционала Программного продукта,
                        будет точной и достоверной.
                    </li>
                </ul>
                <p>
                    8.2. Сервис не несет ответственность за:
                </p>
                <p>
                    8.2.1. Использование Пользователями товарных знаков, логотипов третьих лиц.
                </p>
                <p>
                    8.2.2. Возможную потерю или порчу данных, а также другие последствия любого характера, которые могут
                    произойти из-за нарушения Пользователем настоящих Правил.
                </p>
                <p>
                    8.2.3. Любой ущерб компьютеру Пользователя, мобильным устройствам, любому другому оборудованию или
                    программному обеспечению, вызванный или связанный с использованием сайтов, доступных по
                    гиперссылкам, размещенным в Программном продукте, как Пользователями, так и третьими лицами.
                </p>
                <p>
                    8.2.4. Любую информацию, размещенную на сайтах третьих лиц, к которым Пользователь получает доступ
                    посредством Программного продукта или через Контент третьих лиц, размещенный в Программном продукте,
                    включая, в том числе, любые мнения или утверждения, выраженные на сайтах третьих лиц или в
                    их Контенте. Переход к сайтам третьих лиц, установка программ и потребление услуг третьих лиц
                    осуществляется Пользователем на свой риск.
                </p>
                <p>
                    8.2.5. Безопасность (устойчивость) выбранного Пользователем пароля.
                </p>
                <p>
                    8.2.6. Получение третьими лицами доступа к Профилю Пользователя за исключением случаев,
                    предусмотренных настоящих Правил.
                </p>
                <p>
                    8.2.7. Задержки или невыполнение обязательств, если они напрямую или косвенно связаны со стихийными
                    бедствиями, принудительными действиями или вызваны причинами, не подлежащими разумному контролю,
                    включая, помимо прочего, сбои в работе Интернета, вычислительной техники, устройств связи или
                    другого
                    оборудования, перебои в электроснабжении, забастовки, трудовые споры, массовые беспорядки,
                    гражданские восстания и мятежи, нехватку рабочей силы или дефицит материалов, пожары, наводнения,
                    штормы, форс-мажорные обстоятельства, войну, правительственные меры, распоряжения местных или
                    зарубежных судов или органов правосудия, невыполнение обязательств третьими сторонами,
                    а также перебои в работе систем отопления, освещения или кондиционирования.
                </p>
                <p>
                    8.2.8. Возможные сбои и перерывы в работе Программного продукта, прекращение функционирования
                    Программного продукта и вызванные ими потерю информации, если это произошло не по вине Сервиса.
                </p>
                <p>
                    8.2.9. Ущерб, причиненный Пользователю в результате взаимодействия с рекламой, размещенной
                    в Программном продукте.
                </p>
                <p>
                    8.2.10. Возможные убытки, причиненные Пользователю в связи с принятием мер пресечения или
                    предотвращения нарушений в Программном продукте, связанных с ограничением/блокировкой доступа
                    Пользователей к Программному продукту.
                </p>
                <p>
                    8.3. Ответственность Сервиса по обязательствам, предусмотренным настоящими Правилами, наступает
                    только при наличии вины (умысла или неосторожности).
                </p>
                <p>
                    8.4. Сервис не участвует в разрешении конфликтов между Пользователем и третьими лицами,
                    включая правообладателей, за исключением случаев, когда решение о таком участии принято судом.
                </p>
                <p>
                    8.5. Пользователь несет ответственность:
                </p>
                <p>
                    8.5.1. Перед третьими лицами за свои действия, связанные с использованием Программного продукта,
                    в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц,
                    а также за соблюдение законодательства при использовании Программного продукта.
                </p>
                <p>
                    8.5.2. За любой Контент, который он загружает в Программный продукт или иным образом доводит до
                    всеобщего сведения посредством Программного продукта или с его помощью ответственность несет
                    Пользователь. Пользователь обязуется самостоятельно разрешать претензии третьих лиц, связанные
                    с неправомерным размещением Контента.
                </p>
                <p>
                    8.6. Любое действие, совершенное с использованием Профиля, считается действием, совершенным самим
                    Пользователем или уполномоченным им лицом.
                </p>
                <p>
                    8.7. Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для
                    заключения и исполнения настоящих Правил.
                </p>
                <p>
                    8.8. Сервис не может гарантировать, что Пользователь действительно является тем, кем представляется,
                    а также что Контент, опубликованный Пользователем посредством Программного продукта,
                    соответствуют действительности.
                </p>

                <h2>9. ПЛАТЕЖИ И ВОЗВРАТ ДЕНЕЖНЫХ СРЕДСТВ</h2>
                <p>
                    9.1. Сервис предоставляет Пользователю следующие виды платных услуг – Приобретение Подписки.
                </p>
                <p>
                    9.2. Приобретение Подписки осуществляется Пользователями в безналичной форме на условиях 100%
                    предоплаты на расчетный счет Сервиса. В качестве валюты платежа применяется белорусский рубль.
                </p>
                <p>
                    9.3. Моментом оплаты является дата зачисления денежных средств на расчетный счет Сервиса. При этом
                    банковские расходы относятся на счет Пользователя.
                </p>
                <p>
                    9.4. Пользователь вправе в одностороннем порядке расторгнуть договор оказания услуг на условиях,
                    предусмотренных таким договором и в соответствии с гражданским законодательством
                    Республики Беларусь.
                </p>
                <p>
                    9.5 В случае нарушения Сервисом сроков оказания услуги Пользователь имеет право расторгнуть договор
                    оказания услуг. При этом денежная сумма, возвращаемая Пользователю при расторжении договора оказания
                    услуг, определяется на момент предъявления Пользователем требования, а если оно добровольно
                    не удовлетворено, - на момент вынесения судом соответствующего решения.
                </p>
                <p>
                    9.6. Расчеты с Пользователем при расторжении договора оказания услуг осуществляются в той же форме,
                    в которой производилась оплата услуги, если иное не предусмотрено соглашением сторон.
                </p>
                <p>
                    9.7. При расторжении договора оказания услуг и возврате Пользователю уплаченной денежной суммы
                    Сервис не вправе требовать от Пользователя предъявления документа, удостоверяющего личность,
                    за исключением случая, если при заключении договора использовались данные документа,
                    удостоверяющего личность Пользователя.
                </p>
                <p>
                    9.8. В случае необходимости возврата денежных средств по операциям, проведенными с ошибками,
                    Пользователь вправе обратиться к Сервису с письменным заявлением и приложением документов,
                    подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу
                    электронной почты: buh@bytorg.by
                </p>
                <p>
                    9.9. Предоставляемая Пользователем персональная информация (например: имя, адрес, телефон, e-mail,
                    номер банковской карты и прочее) является конфиденциальной и не подлежит разглашению.
                </p>

                <h2>10. ОБМЕН ИНФОРМАЦИЕЙ</h2>
                <p>
                    10.1. Сообщения Сервиса, предназначенные для Пользователя, публикуются для всеобщего доступа в
                    Программном продукте и/или рассылаются индивидуально по электронным адресам, указанным при
                    регистрации Профиля.
                </p>
                <p>
                    10.2. При этом Пользователь понимает, принимает и соглашается, что рассылаемые сообщения и/или их
                    отдельные части могут иметь рекламный характер, а также могут содержать рекламные, информационные
                    и иные сообщения контрагентов Сервиса.
                </p>
                <p>
                    10.3. Сообщения, опубликованные посредством Программного продукта, считаются доставленными
                    Пользователю с момента их публикации.
                </p>
                <p>
                    10.4. Сообщения Пользователей, предназначенные для Сервиса, пересылаются способами,
                    предложенными Программным продуктом.
                </p>

                <h2>11. ПОРЯДОК РАЗРЕШЕНИЯ ОБРАЩЕНИЙ И СПОРОВ: </h2>
                <p>
                    11.1. Разрешение обращений и споров между Пользователем и Сервисом по вопросам, связанным
                    с исполнением настоящих Правил, а также нарушением иными лицами прав и законных интересов
                    Пользователей посредством Программного продукта осуществляется в следующем претензионном порядке:
                </p>
                <p>
                    11.2. В адрес Сервиса по электронной почте на адрес office@bytorg.by направляется обращение,
                    содержащее информацию о факте нарушения прав Пользователя, с приложением документов, подтверждающих
                    правомочия заявителя. В обращении указывается адрес страницы Программного продукта, которая содержит
                    данные, нарушающие права, и излагается полное описание сути нарушения прав.
                </p>
                <p>
                    11.3. Сервис обязуется рассмотреть надлежаще оформленное обращение в течение 15 (пятнадцати) рабочих
                    дней с даты ее получения и уведомить заявителя о результатах ее рассмотрения. В том числе, Сервис
                    вправе запросить дополнительные документы, свидетельства, данные, подтверждающие
                    законность обращения.
                </p>
                <p>
                    11.4. В случае признания претензии правомерной, Сервис обязуется принять все возможные меры,
                    необходимые для прекращения нарушения прав заявителя.
                </p>
                <p>
                    11.5. Сервисом не рассматриваются анонимные претензии или претензии, не позволяющие идентифицировать
                    Пользователя на основе предоставленных им при регистрации данных, или претензии, не содержащие
                    данных, нарушающих права; содержащие ложную информацию и (или) документы, не обладающие признаками
                    достоверности; в отношении вопросов, по которым ранее Сервисом был направлен ответ Пользователю
                    (повторные обращения); содержащие оскорбления, угрозы или обращения, изложенные в резкой негативной
                    форме; направленные с нарушением иных условий и порядка рассмотрения обращений,
                    предусмотренных Сервисом.
                </p>
                <p>
                    11.6. При отказе Сервиса удалить Контент на основании обращения Пользователя, Контент может быть
                    удален на основании решения суда.
                </p>
                <p>
                    11.7. Взаимодействие Сервиса с Пользователем в рамках рассмотрения обращения Пользователя
                    осуществляется с использованием указанного в обращении адреса электронной почты.
                </p>

                <h2>12. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </h2>
                <p>
                    12.1. Настоящие Правила действует в течение всего периода использования Пользователем Программного
                    продукта.
                </p>
                <p>
                    12.2. Регулирование и толкование настоящих Правил осуществляется в соответствии с законодательством
                    Республики Беларусь. Во всем том, что не урегулировано настоящими Правилами, Сервис и Пользователи
                    руководствуются действующим законодательством Республики Беларусь.
                </p>
                <p>
                    12.3. В случае возникновения любых споров или разногласий, связанных с исполнением настоящих Правил,
                    Стороны приложат все усилия для их урегулирования путем проведения переговоров.
                </p>
                <p>
                    12.4. Споры, не решенные путем переговоров, разрешаются Сторонами с соблюдением претензионного
                    порядка в соответствии с требованиями, установленными Хозяйственным процессуальным кодексом
                    Республики Беларусь.
                </p>
                <p>
                    12.5. В случае, если Стороны не придут к соглашению, споры подлежат рассмотрению в Экономическом
                    суде Гомельской области. Применимое право – законодательство Республики Беларусь.
                </p>
                <p>
                    12.6. Стороны признают, что недействительность части настоящих Правил в соответствии с применимым
                    правом не влечет за собой недействительности прочих его частей.
                </p>

                <h2>15. АДРЕС И РЕКВИЗИТЫ СЕРВИСА:</h2>
                <p>Общество с ограниченной ответственностью «Байторг Бай» </p>
                <p>Местонахождение (юридический адрес): 246015, г. Гомель, ул. Лепешинского, 9Б</p>
                <p>УНП: 491388945</p>
                <p>Расчетный счет: BY04PJCB30124090351000000933 </p>
                <p>в «ПРИОРБАНК» ОАО ЦБУ 400, </p>
                <p>БИК PJCBBY2X</p>
                <p>Адрес банка: Красноармейская, 3а, Гомель</p>
            </div>
        </section>
        <footer class="footer">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-sm-4 col-lg-5">
                        <img src="../assets/img/logo.png" alt="" class="footer-logo" width="113" height="54">
                    </div>
                    <div class="col-sm-8 col-lg-7">
                        <div class="row">
                            <div class="offset-md-6 col-md-6">
                                <router-link
                                        :to="privacyPolicyPath"
                                        exact
                                        class="footer-link"
                                >
                                    <span>Политика конфиденциальности</span>
                                    <ico name="icoOpenInNew" color="#79919C"></ico>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-xl-4 order-xl-2">
                        <div class="footer-contacts">
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Телефон</p>
                                <a class="footer-contacts-item-link" href="tel:+375447228833">+375447228833</a>
                            </div>
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Вопросы и предложения</p>
                                <a class="footer-contacts-item-link" href="mailto:office@bytorg.by">office@bytorg.by</a>
                            </div>
                            <div class="footer-contacts-item">
                                <p class="footer-contacts-item-title">Бухгалтерия</p>
                                <a class="footer-contacts-item-link" href="mailto:buh@bytorg.by">buh@bytorg.by</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 order-xl-1">
                        <p class="footer-copyright">© ООО “Байторг Бай”, 2024</p>
                        <p class="footer-copyright">Копирование материалов запрещено. При согласованном использовании
                            материалов сайта необходима ссылка на ресурс.</p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
    import {homePath} from "@/router/home"
    import {privacyPolicyPath} from "@/router/privacyPolicy"
    import {termsOfUsePath} from "@/router/termsOfUse"
    import Configuration from "@/configuration"

    export default {
        name: "TermsOfUse",
        data() {
            return {}
        },
        computed: {
            baseUrl() {
                return Configuration.value('apiEndpoint')
            },
            privacyPolicyPath() {
                return privacyPolicyPath
            },
            termsOfUsePath() {
                return termsOfUsePath.substring(1)
            },
            homePath() {
                return homePath
            }
        }
    }
</script>

<style scoped>
</style>